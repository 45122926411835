import axios from 'axios';
import MD5 from 'crypto-js/md5';
import { REQUEST_HOST as UTILS_REQUEST_HOST } from '@yaahlan/utils-tools';

export const showMessage = (message: string) => {
  window.mm.ui.showMessage({
    status: 1,       // Int: 0-成功, 1-失败, 2-无表情图标  客户端可以根据状态显示不同的形式。
    message     // String: 消息内容
  })
}

export const judgeClient = () => {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
  const isIOS = !!u.match(/iPhone/);     //判断是否是 iOS终端
  console.log('是否是Android：' + isAndroid); //true,false
  console.log('是否是iOS：' + isIOS);
  if (isAndroid) {
    return 'android';
  } else if (isIOS) {
    return 'ios';
  } else {
    return 'unknow';
  }
}

const client = judgeClient();

export const platform = {
  ios: client === 'ios',
  android: client === 'android',
}

export const closeWebview = () => {
  window.mm.ui.close();
}

export const getLocale = () => {
  let locale = navigator.userAgent.match(/ lan\/(\w+)/)?.[1] || 'en';

  if (location.hostname.includes('localhost') || location.hostname.includes('172')) {
    locale = 'en';
  }

  console.log('>>>>> locale: ', locale);

  return locale;
}

export const getSUDLocale = () => {
  let locale = navigator.userAgent.match(/ lan\/(\w+)/)?.[1] || 'en'

  if (location.hostname.includes('localhost') || location.hostname.includes('172')) {
    locale = 'zh'
  }

  if (locale === 'zh') {
    locale = 'zh-CN'
  } else if (locale === 'en') {
    locale = 'en-US'
  } else if (locale === 'ar') {
    locale = 'ar-SA'
  }

  return locale
}

export const LOCALE = getLocale();

export const FLIPOVER_LANGUAGE = 'ar';

export const dynamicImportLocale = async () => {
  const language = LOCALE;

  let message = {};

  switch (language) {
    case 'en':
      message = (await import('../locale/en')).default;
      break;
    case 'zh':
      message = (await import('../locale/zh')).default;
      break;
    case 'ar':
      message = (await import('../locale/ar')).default;
      break;
    default:
      message = (await import('../locale/en')).default;
      break;
  }

  return { language, message };
}

export const getStatusBarHeight = () => new Promise<number>((resolve) => {
  console.log('>>>>> getStatusBarHeight');

  window.mm.build({
    common: ['ui', 'getStatusBarHeight', {}, function (e: {
      code: number;
      data: string;
    } | {
      height: number;
    }) {
      console.log('>>>>> getStatusBarHeight: ', e);

      try {
        if ('data' in e && typeof e.data === 'string') {
          const res = JSON.parse((e as {
            code: number;
            data: string;
          }).data);
          resolve(res.height / window.devicePixelRatio);
        } else {
          resolve((e as {
            height: number;
          }).height / window.devicePixelRatio);
        }
      } catch (e) {
        console.log('>>>>> getStatusBarHeight error: ', e);
        resolve(0)
      }
    }]
  });
})

export const getEnv = () => {
  const hostname = location.hostname;

  if (hostname === 'www.yaahlan.fun' || hostname === 's.immomo.com') {
    return 'prod';
  } else if (hostname === 'test-s.immomo.com') {
    return 'staging';
  } else {
    return 'dev';
  }
}

export const ENV = getEnv();

const getReuqestHost = () => {
  if (ENV === 'prod') {
    return 'https://melon-gateway-os.immomo.com';
  } else if (ENV === 'staging') {
    return 'https://melon-gateway-stage.immomo.com';
  } else {
    return 'https://melon-gateway-stage.immomo.com';
  }
}

export const REQUEST_HOST = UTILS_REQUEST_HOST;

export const closeLoading = () => {
  window.mm.build({
    common: [
      'game',
      'closeLoading',
      {
        gameType: 'YaParty',
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { }
    ]
  })
}

export const joinChannel = (channelId: string) => {
  window.mm.build({
    common: [
      'agora',
      'joinChannel',
      {
        channelId,
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { }
    ]
  })
}

export const leaveChannel = (channelId: string) => {
  window.mm.build({
    common: [
      'agora',
      'leaveChannel',
      {
        channelId,
        reason: 'leave',
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { }
    ]
  })
}

export const forbidLeftSlide = () => {
  window.mm.ui.forbidLeftSlide();
}

export const showGameAudioBar = ({ count }: { count: number }) => {
  window.mm.build({
    common: [
      'game',
      'showGameAudioBar',
      {
        count,
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { }
    ]
  })
}

export const MD5_SECRET_KEY = 'FuGB02oL9uypYm2bO0RQse32WrklHXae';

export const roomStatusChangeNotify = async (data: {
  roomId: string,
  type: number,
  userId?: string,
  gameId: string,
  gameTime?: number,
}) => {
  const generateSignature = (
    params: Record<string, string | number>,
    secretKey: string
  ) => {
    // 将参数按照键名排序
    const sortedKeys = Object.keys(params).sort();

    // 构建排序后的参数字符串
    let sortedParams = '';
    for (const key of sortedKeys) {
      sortedParams += key + params[key];
    }

    // 拼接密钥
    const signStr = sortedParams + secretKey;

    console.log('>>>>> signStr: ', signStr);

    // 计算 MD5 值
    return MD5(signStr).toString();
  };

  data = {
    gameTime: 0,
    ...data,
  }

  console.log(
    'roomStatusChangeNotify',
    {
      ...data,
      sign: generateSignature(data, MD5_SECRET_KEY),
    }
  )

  const res = await axios.post(
    ENV !== 'prod'
      ? 'https://melon-gateway-stage.immomo.com/yaahlan/game-match/momo/room/status/roomStatusChangeNotify'
      : 'https://melon-gateway-os.immomo.com/yaahlan/game-match/momo/room/status/roomStatusChangeNotify',
    {
      ...data,
      sign: generateSignature(data, MD5_SECRET_KEY),
    }
  );

  return res;
}

export const refreshGameAudioBar = ({ seats }: {
  seats: {
    userId: string,
  }[]
}) => {
  console.log('refreshGameAudioBar', JSON.stringify(seats));
  window.mm.build({
    common: [
      'game',
      'refreshGameAudioBar',
      {
        seats,
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { }
    ]
  })
}

export const showGameResult = ({ result }: {
  result: {
    rank: number,
    age: number,
    gender: number,
    relationStatus: number,
    userId: string,
    avatar: string,
    nickname: string,
  }[]
}) => {
  window.mm.build({
    common: [
      'game',
      'showGameResult',
      {
        result,
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => { }
    ]
  })
}