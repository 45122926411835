<template>
  <div id="app" :style="style">
    <keep-alive>
      <router-view  v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view  v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { LOCALE, FLIPOVER_LANGUAGE } from '@/utils';

@Component({})
export default class index extends Vue {
  style = '';

  created() {
    // 用户协议不用镜像
    if (
      LOCALE === FLIPOVER_LANGUAGE &&
      !window.location.hash.includes('user-policy') &&
      !window.location.hash.includes('sud-client')
    ) {
      this.style = `transform: scaleX(-1);text-align: right;`
    }
  }
}
</script>

<style lang="scss">
@import url('@tantan/normalize-css');
// 引入mixin样式
@import '@tantan/normalize-css/scss/mixin.scss';
// 引入animate样式
@import '@tantan/normalize-css/scss/animate.scss';

* {
  font-family: 'Aktiv Grotesk Trial';
}
</style>
