import Vue from "vue";
import "@fes/mk-bridge"; // mm
import router from "./router/index";
import App from "./App.vue";
import "@tantan/normalize-css";
import VueI18n from 'vue-i18n';
import { dynamicImportLocale } from "./utils";

try {
  (window as any).mm.ui.setPulldown({
    type: 0 // Int: 1-允许下拉，0-不允许下拉
  });
} catch { console.log('bridge err'); }

Vue.config.productionTip = false;

Vue.use(VueI18n)

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n();
// {
//   locale: navigator.userAgent.match(/lan\/(\w+?) /)?.[1] || 'en', // 设置地区
//   messages: {
//     en: {
//       message: {
//         hello: 'hello world'
//       }
//     },
//     ja: {
//       message: {
//         hello: 'こんにちは、世界'
//       }
//     }
//   }, // 设置地区信息
// }

dynamicImportLocale().then(({ language, message }) => {
  i18n.setLocaleMessage(language, message);
  i18n.locale = language;
});

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount("#app");
